<template>
  <g>
    <path d="M6.096 14.222a2.603 2.603 0 103.682 3.682l-3.682-3.682z" />
    <path
      d="M18.97 14.552l.931-.929c2.6-2.59 2.864-6.847.343-9.515a6.732 6.732 0 00-9.64-.148L9.471 5.088A6.72 6.72 0 015.673 6.98l-1.82.26a2.157 2.157 0 00-1.22.608 2.146 2.146 0 000 3.043L13.146 21.37c.843.84 2.21.84 3.053 0 .33-.329.544-.757.611-1.217l.26-1.814a6.667 6.667 0 011.898-3.787z"
      fill-opacity=".35"
    />
  </g>
</template>

<script>
export default {
  name: "NotificationIcon"
};
</script>
